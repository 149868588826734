<template>
  <sdCards headless>
    <div v-if="!isLoading" style="text-align: center">
      <p class="trading__nothing mt-5 mb-15" v-html="i18n.t('option.notExist')" />
      <sdButton type="success" size="small" @click="openBuyOptionModal">
        {{ i18n.t('option.buy') }}
      </sdButton>
    </div>
    <div class="loading-data-trading" v-else>
      <a-spin size="large"></a-spin>
    </div>
  </sdCards>
</template>

<script>
import { defineComponent, reactive } from 'vue';
import { useI18n } from 'vue-i18n';

const EmptyOptions = defineComponent({
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const i18n = reactive(useI18n());

    const openBuyOptionModal = () => {
      emit('openModal');
      // dispatch('toggleBuyOptionModal');
    };

    return { i18n, openBuyOptionModal };
  },
});

export default EmptyOptions;
</script>
<style lang="scss" scoped>
.trading__nothing {
  opacity: 0.7;
}
</style>
